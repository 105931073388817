import { AIChatMessageProps, AIChatMetadataData } from "#components/common/AIChat/types";
import { AdminBriefStatus } from "#customTypes/brief";
import { PresignedUrlData } from "#customTypes/types";

export enum BriefBuilderStep {
  Brief = "brief",
  BriefEditor = "brief_editor",
  General = "general",
  Audience = "audience",
  Survey = "survey",
  Launch = "launch",
  Share = "share",
}

export enum BriefResponseType {
  Bulbshare = "bulbshare",
  Comment = "comment",
  Survey = "survey",
}

export enum BriefTargetingType {
  Everyone = "everyone",
  Segment = "segment",
}

export enum SurveyPreviewStep {
  idle,
  saving,
  saved,
  checking,
  incompatible,
  compatible,
  errorWhileChecking,
}

export type BriefBuilderRoutes = { [key in BriefBuilderStep]: string };

export interface BriefBuilderBriefStep {
  title: string;
  cover: string;
}

export interface BriefBuilderContentStep {
  content: string;
}

export interface BriefBuilderGeneralStep {
  projectId: number | null;
  projectName?: string;
  channelId: number;
  startsOn: Date;
  endsOn: Date | null;
  responseType: BriefResponseType;
  targetingType: BriefTargetingType;
}

export interface CreateEmptyBriefRequest {
  organisationId: number;
  channelRef?: string;
}

export interface CreateEmptyBriefResponse {
  briefRef: string;
}

export interface BriefBuilderData {
  briefId: number;
  briefRef: string;
  currentBuildStep: BriefBuilderStep;
  steps: BriefBuilderStep[];
  organisationId?: number;
  channelId?: number;
  channelRef?: string;
  channelName?: string;
  internalName: string | null;
  title: string;
  startsOn: string;
  endsOn: string;
  parentBriefRef?: string;
  hasChildBrief: boolean;
  responseType: BriefResponseType;
  targetingType: BriefTargetingType;
  contentDocumentJson: string | null;
  generatedKeyConcept: string | null;
  coverPhoto: string;
  coverVideoWeb: string;
  coverWallpaperId: string | null;
  unsplashImageId?: string | null;
  status: AdminBriefStatus;
  audience?: BriefAudience;
  launchNotification?: BuilderLaunchNotificationType | null;
  notifications?: BuilderScheduleNotificationType[];
}

export interface CalculateBriefBuilderStepsRequest {
  briefRef: string;
  responseType: BriefResponseType;
  targetingType: BriefTargetingType;
  activeStep: BriefBuilderStep;
  launched?: boolean;
}

export interface GetBriefBuilderStepsResponse {
  steps: BriefBuilderStep[];
}

export interface GetBriefBuilderLockResponse {
  locked: boolean;
  user?: {
    id: number;
    tabId: string;
    displayName: string;
  };
}

export interface RequestBriefBuilderLock {
  briefRef: string;
  tabId: string;
}

export interface BriefWallpaper {
  id: string;
  url: string;
  key: string;
  order: number;
}

export interface GetBriefWallpapersResponse {
  data: BriefWallpaper[];
}

export interface GetTiptapTokenResponse {
  token: string;
}

export interface SaveBriefBaseResponse {
  briefId: number;
  briefRef: string;
  steps: BriefBuilderStep[];
  currentBuildStep: BriefBuilderStep;
}

export interface SaveBriefTitleRequest {
  briefRef: string;
  title: string;
}

export enum SaveBriefCoverType {
  Photo = "photo",
  Video = "video",
  Wallpaper = "wallpaper",
  UnsplashImage = "unsplash_image",
}

export interface SaveBriefCoverRequest {
  briefRef: string;
  coverPhoto?: File;
  coverVideo?: File;
  wallpaperId?: string;
  unsplashImageId?: string;
}

export interface SaveBriefCoverResponse {
  coverPhoto?: string;
  coverVideoWeb?: string;
  coverWallpaperId?: string;
}

export interface SaveBriefContentRequest {
  briefRef: string;
  contentDocumentJson: string;
  contentDocumentHtml: string;
}

export interface SaveBriefGeneralRequest {
  briefRef: string;
  channelId: number;
  internalName: string;
  responseType: BriefResponseType;
  targetingType: BriefTargetingType;
}

export interface BuilderSegmentationAgeType {
  min: number;
  max: number;
}

export type BuilderUserPropertyCondition = "matches" | "exists" | "not_exists";

export interface BuilderUserPropertyType {
  condition: BuilderUserPropertyCondition;
  customPropertyTypeId: number;
  customPropertyTypePropName?: string;
  customPropertyTypeIsInternal?: boolean;

  // only for condition "matches"
  customPropertyValues?: string[];
}

export interface BuilderBehaviouralType {
  conditional: "and" | "or";
  properties: BuilderUserPropertyType[];
}

export interface BriefBuilderResponsesType {
  autoTranslateLanguageId: number | null;
  requiredResponses: number | null;
  responsesPerParticipant: number | null;
}

export interface BriefAudience {
  segmentationGender: string[];
  segmentationCountry: number[];
  segmentationAge: BuilderSegmentationAgeType;
  behavioural: BuilderBehaviouralType;
  responses: BriefBuilderResponsesType;
}

export interface SaveBriefAudienceRequest extends BriefAudience {
  briefRef: string;
}

export interface CalculateBriefAudienceResponse {
  reachedMemberCount: number;
  maximumMemberCount: number;
}

export interface BriefContentImagePresignedUrlData extends PresignedUrlData {
  briefBuilderMediaId: string;
  briefBuilderMediaUrl: string;
}

export interface SaveBriefLaunchRequest {
  briefRef: string;
  startsOn: string;
  endsOn: string | null;
  launchNotification: BuilderLaunchNotificationType | null;
  notifications: BuilderScheduleNotificationType[];
}

export interface SaveBriefLaunchResponse extends SaveBriefBaseResponse {
  launchNotification: BuilderLaunchNotificationType | null;
  notifications: BuilderScheduleNotificationType[];
}

export interface SaveBriefSurveyRequest {
  briefRef: string;
}

export interface DeleteBriefNotificationRequest {
  briefRef: string;
  notificationId: number;
}

export enum BuilderNotificationStatus {
  Scheduled = "Scheduled",
  Started = "Started",
  Failed = "Failed",
  Succeeded = "Succeeded",
}

export enum BuilderNotificationType {
  Launch = "launch",
  Schedule = "schedule",
}

export interface BuilderPushNotificationType {
  message: string;
}

export interface BuilderEmailNotificationType {
  subject: string;
  body: string;
}

export interface BuilderBaseNotificationType {
  status: BuilderNotificationStatus;
  push?: BuilderPushNotificationType;
  email?: BuilderEmailNotificationType;
  execEnd?: string | null;
}

export interface BuilderScheduleNotificationType extends BuilderBaseNotificationType {
  id?: number;
  scheduled: string;
  skipUsersResponded: boolean;
}

export interface BuilderLaunchNotificationType extends BuilderBaseNotificationType {
  enabled: boolean;
}

export interface BriefBuilderChatRequest {
  channelRef: string;
  prompt: string;
  messages: AIChatMessageProps[];
}

export interface BriefBuilderChatResponse {
  messages: AIChatMessageProps[];
}

export enum BriefGenerationStatus {
  Pending = "pending",
  Processed = "processed",
  PartiallyProcessed = "partially_processed",
  Failed = "failed",
}

export enum BriefGenerationStep {
  Base = "base",
  Content = "content",
  Survey = "survey",
}

export interface GenerateBriefRequest {
  channelRef: string;
  templateData: AIChatMetadataData;
}

export interface GetBriefGenerationStatusRequest {
  briefGenerateRequestId: string;
}

export interface GenerateBriefResponse {
  briefGenerateRequestId: string;
  briefRef: string | null;
  status: BriefGenerationStatus;
  step: BriefGenerationStep | null;
  error: string | null;
}

export interface GenerateBriefImageRequest {
  briefRef: string;
  prompt: string;
}

export interface GenerateBriefImageResponse {
  base64: string;
}
