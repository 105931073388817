import { FetchPollItemsByBriefRefParams } from "#apis/fetchPollItemsByBriefRef";
import { GetBriefCardInfoRequest, GetBriefDetailsRequest } from "#customTypes/brief";
import { QueryKeyParamsFromRequest } from "#customTypes/reactQuery";
import { FetchFeedProps } from "#customTypes/types";
import { omit } from "radash";

export enum QueryKeyEntity {
  brief = "brief",
  channels = "channels",
  chat = "chat",
  pollItems = "pollItems",
  briefBuilder = "brief-builder",
  superAdmin = "super-admin",
  unsplash = "unsplash",
  interests = "interests",
  organisations = "organisations",
}

export enum QueryKeyType {
  list = "list",
  details = "details",
  channel = "channel",
  token = "token",
}

export const BriefQueryKeys = {
  list: (params?: QueryKeyParamsFromRequest<FetchFeedProps>) =>
    createQueryKey([QueryKeyEntity.brief, QueryKeyType.list], params),
  details: (params?: QueryKeyParamsFromRequest<GetBriefDetailsRequest>) =>
    createQueryKey([QueryKeyEntity.brief, QueryKeyType.details], params),
  cardInfo: (params?: QueryKeyParamsFromRequest<GetBriefCardInfoRequest>) =>
    createQueryKey([QueryKeyEntity.brief, "card-info"], params),
};

export const ChatQueryKeys = {
  channel: [QueryKeyEntity.chat, QueryKeyType.channel],
  token: [QueryKeyEntity.chat, QueryKeyType.token],
};

export const PollItemsQueryKeys = {
  listByBriefRef: (params?: QueryKeyParamsFromRequest<FetchPollItemsByBriefRefParams>) =>
    createQueryKey(
      [QueryKeyEntity.pollItems, QueryKeyEntity.brief, QueryKeyType.list],
      params
    ),
};

export const createQueryKey = (baseKeys: string[], params?: any) => [
  ...baseKeys,
  ...(params ? [omit(params, ["page", "pageSize", "start", "cookies"])] : []),
];
